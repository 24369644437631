.container {
  width: 100vw;
  height: 100vh;

  background-image: url('/assets/wires/w-nav-light-200.svg');
  background-color: var(--c-background-100);
  background-size: cover, cover;
  background-position: center, center;

  position: fixed;
  top: calc(80px - 100vh);
  left: 0;
  right: 0;
  z-index: 3;

  transition: top 200ms ease-in-out, background 1000ms ease;

  .navigation {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    max-width: 260px;
    height: calc(100% - 90px);
    margin: 0 auto;
    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header {
    width: 100%;
    height: 80px;

    position: absolute;
    bottom: 0;
    left: 0;

    overflow: hidden;
    padding: 0 24px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      width: 80px;
      height: 74px;
      overflow: hidden;

      margin-bottom: -50px;
      transition: margin 300ms ease-in-out;

      svg {
        fill: var(--c-element-200);
        width: 100%;
      }

      &.open {
        margin-bottom: -10px;
      }
    }

    .buttonContainer {
      position: fixed;
      top: 20px;
      right: 24px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      z-index: 1;

      button {
        color: var(--c-element-100);
        text-transform: uppercase;
        letter-spacing: 2px;
        line-height: 1;

        background: transparent;
        border: 0;

        display: flex;
        align-items: center;
        justify-content: space-between;

        .icon {
          path {
            stroke: var(--c-candy-red);
            stroke-width: 2px;
          }
        }

        & + button {
          margin-left: 32px;
        }

        &.menu {
          width: 80px;

          svg {
            stroke: var(--c-candy-red);
            stroke-width: 2px;
            width: 16px;
            height: 18px;
            margin-left: 8px;
          }
        }

        &.toggle {
          width: 24px;
          height: 40px;
          position: relative;

          &::before {
            content: '';
            width: 100%;
            height: 12px;
            border-radius: 6px;

            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);

            background: var(--c-background-200);
            box-shadow: inset 0 0 10px 2px rgba(0, 0, 0, 14%);
          }

          &::after {
            content: '';
            width: 12px;
            height: 12px;
            border-radius: 6px;
            background: var(--c-candy-red);

            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);

            transition: left 300ms ease-in-out;
          }

          &.dark::after {
            left: 50%;
          }
        }
      }
    }
  }

  &.dark {
    background-image: url('/assets/wires/w-nav-dark-100.svg');
    background-color: var(--c-background-100);

    .header .logo svg {
      fill: var(--c-candy-red);
    }
  }

  &.open {
    top: 0;
  }
}

@media (min-width: 768px) {
  .container {
    top: 0;
    height: 80px;

    .navigation {
      max-width: unset;
      left: 120px;
      height: 100%;
    }

    .header {
      .logo.open {
        margin-bottom: -50px;
      }

      .buttonContainer button.menu {
        display: none;
      }
    }
  }
}
