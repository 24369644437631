.button {
  color: var(--c-element-100);
  text-transform: uppercase;
  letter-spacing: 3px;

  background: transparent;
  border: 0;
  padding: 0.8rem 2.4rem;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  z-index: 1;

  transition: background-color 300ms ease-in-out, filter 300ms ease-in-out;

  &::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 300%;
    background: var(--c-candy-red);
    z-index: -1;

    transition: top 300ms ease-in-out, transform 300ms ease-in-out;
  }

  &.outline {
    border: 4px solid var(--c-candy-red);
    background: var(--c-background-200);

    &:hover,
    &:focus {
      &.light {
        color: var(--c-polar-white);
      }

      &::after {
        top: 0;
        transform: rotate(90deg) scale(3);
      }
    }
  }

  &.fill {
    border: 4px solid var(--c-candy-red);
    background: var(--c-candy-red);
    color: var(--c-polar-white);
  }

  &.outline,
  &.fill {
    &:disabled {
      opacity: 0.3;
    }
  }
}
