.toast {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 42.5rem;
  padding: 1rem;
  text-align: left;
  border: none;
  background: var(--c-element-200);
  box-shadow: 0 10px 24px rgba(0, 0, 0, 0.2);

  display: flex;
  gap: 0.8rem;
  z-index: 5;

  transition: top 300ms ease;

  .close {
    position: absolute;
    top: 0;
    right: 0;
    width: 1.6rem;
    height: 1.6rem;
    border: none;
    padding: 0.4rem;
    background: var(--c-element-200);
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
      stroke: var(--c-candy-red);
    }
  }

  .details {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    strong {
      color: var(--c-candy-red);
      flex: 1;

      svg {
        fill: var(--c-candy-red);
        width: 1.6rem;
        height: 1.4rem;
        margin-right: 0.8rem;
      }
    }

    span {
      font-size: 1.3rem;
      line-height: 1.2;

      a {
        color: var(--c-background-100);

        &:hover {
          color: var(--c-candy-red);
        }
      }

      &.song {
        font-size: 1.5rem;
        font-weight: var(--f-bold);
      }
    }

    .logo {
      width: 10.6rem;
      height: 1.5rem;
      margin-top: 1rem;
      opacity: 0.8;
      fill: var(--c-background-100);
    }
  }

  .cover {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      position: relative;
      display: block;
      width: 10rem;
      height: 10rem;
      border: 1px solid var(--c-silver-arrow);
      box-shadow: 0 16px 10px -10px rgba(0, 0, 0, 0.2);
      transition: filter 200ms ease;

      img {
        transition: transform 900ms ease;
      }

      &:hover {
        filter: brightness(1.2);

        img {
          transform: scale(1.1);
        }
      }

      svg {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        fill: var(--c-candy-red);
        width: 2.6rem;
        height: 2.8rem;
        z-index: 1;
      }
    }
  }

  @media screen and (min-width: 425px) {
    top: unset;
    bottom: 2rem;
    left: 0;
    width: 37.5rem;
  }
}
