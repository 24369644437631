.nav {
  width: 100%;
  max-width: 840px;
  margin: 8rem auto 0;
  padding: 24px;
  display: grid;
  gap: 24px;

  @media (min-width: 768px) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0 82px 0 auto;
    gap: 1rem;
  }
}
