.toast {
  padding: 32px;
  background: var(--c-background-100);
  box-shadow: 0 -20px 20px 0px rgb(0 0 0 / 25%);
  filter: brightness(1.4);
  transition: bottom 600ms ease-out;

  @media (min-width: 768px) {
    box-shadow: 10px 10px 20px 0px rgb(0 0 0 / 25%);
  }

  &.show {
    bottom: 0;
  }

  strong,
  span {
    display: block;
    color: var(--c-element-200);
  }

  strong {
    margin-bottom: 8px;
    letter-spacing: 0.5px;
    line-height: 1.2;
    font-size: 1.6rem;
  }
  span {
    font-size: 14px;
    line-height: 1.4;
  }

  button {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 16px;
    right: 16px;
    border: none;
    background: transparent;

    svg {
      stroke: var(--c-element-100);
    }
  }
}
