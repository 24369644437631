.toastContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 428px;
  z-index: 3;

  @media (min-width: 768px) {
    left: 24px;
    transform: none;
  }
}
