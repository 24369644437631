.button {
  height: 3.2rem;
  font-size: 3rem;
  font-family: var(--f-title);
  font-weight: var(--f-bold);
  line-height: 1;
  color: var(--c-element-100);
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  background: transparent;
  border: none;

  overflow: hidden;
  margin: 1rem;
  padding: 1.6rem;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: color 1000ms ease-in-out;

  &::before {
    content: '';
    width: 50%;
    height: 1px;
    background: var(--c-element-100);
    position: absolute;
    bottom: 0;
    left: 200%;
    transition: left 200ms ease-in-out;
  }

  &::after {
    content: '';
    width: 50%;
    height: 1px;
    background: var(--c-element-100);
    position: absolute;
    top: 0;
    right: 200%;
    transition: right 200ms ease-in-out;
  }

  &.active,
  &:hover {
    color: var(--c-candy-red);

    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }
  }

  &.active:last-of-type {
    color: var(--c-candy-red);

    &::before {
      left: 200%;
    }

    &::after {
      right: 200%;
    }
  }

  @media (min-width: 768px) {
    font-size: 1.8rem;
    padding: 4px;
    color: var(--c-candy-red);

    &.active,
    &:hover {
      color: var(--c-element-100);
    }
  }
}
