.button {
  background: var(--c-candy-red);
  width: 4.8rem;
  height: 4.8rem;
  border: none;

  position: fixed;
  right: 0;
  bottom: 0;
  margin-bottom: 2.4rem;
  z-index: 4;

  padding: 0;

  transition: right 300ms ease-in-out;
  @media (prefers-reduced-motion: no-preference) {
    animation: slide-in-right 1s 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  svg {
    fill: none;
    width: 60%;
    height: 60%;
    stroke: var(--c-polar-white);
  }

  &.open {
    right: calc(100% - 5rem);
    background: var(--c-background-200);
    border-color: var(--c-background-200);

    svg {
      stroke: var(--c-candy-red);
      width: 40%;
      height: 40%;
    }

    @media (min-width: 425px) {
      right: calc(42rem - 5rem);
    }
  }
}

.container {
  position: fixed;
  bottom: 0;
  right: -43rem;
  width: 100%;
  height: 100vh;
  background: var(--c-background-100);
  z-index: 3;

  transition: right 200ms ease-in-out, background-color 1000ms ease;

  @media (min-width: 425px) {
    max-width: 428px;
    max-height: calc(100vh - 80px);
  }

  .content {
    height: 100%;

    display: grid;
    grid-template-rows: 30% 70%;
    opacity: 0;
    transition: opacity 300ms ease-in-out 300ms;

    .intro {
      padding: 1.6rem;
      height: 100%;
      overflow: hidden;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    form {
      display: grid;
      height: 100%;
      grid-template-rows: 8.2rem 8.2rem 8.2rem calc(100% - 24.6rem) 8.2rem;

      .formItem {
        display: flex;
        flex-direction: column;
        width: calc(100% - (2 * 1.6rem));
        max-height: 30rem;
        margin: 0 auto;
        position: relative;

        label {
          margin-bottom: 0.4rem;
          font-size: 1.4rem;
          letter-spacing: 1px;
          color: var(--c-element-100);
        }

        input {
          padding: 0.8rem;
        }

        input,
        textarea {
          color: var(--c-element-200);
          background: var(--c-background-200);
          border: none;
          position: relative;

          transition: background-color 600ms ease;

          &:focus {
            outline: 1px solid rgb(210, 1, 32, 0.3);
            filter: none;
          }
        }

        .error {
          position: absolute;
          top: 0;
          right: 0;
          font-size: 1.4rem;
          letter-spacing: 1px;
          color: var(--c-candy-red);
        }

        &.textarea {
          display: grid;
          grid-template-rows: 12% 88%;
          width: 100%;
          height: 100%;
          max-height: unset;

          label {
            padding-left: 16px;
          }

          textarea {
            padding: 0.8rem 1.6rem;
            height: 100%;
          }

          .error {
            right: 1.6rem;
          }
        }
      }

      .buttonContainer {
        background: var(--c-background-200);

        .submitBtn {
          position: absolute;
          bottom: 1.6rem;
          right: 0;
          height: 50px;
        }

        .resetBtn {
          position: absolute;
          bottom: 1.6rem;
          right: 10rem;
          height: 5rem;
          color: var(--c-element-100);
          background: var(--c-background-200);
          border-color: var(--c-background-200);
        }
      }

      .submitting {
        height: 400%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        letter-spacing: 1px;

        svg {
          width: 5.6rem;
          height: 5.6rem;
          margin-bottom: 1.2rem;
          animation: rotating 6000ms linear infinite;

          path {
            stroke: var(--c-element-100);
          }
        }
      }
    }

    .message {
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 1.6rem;
      background: var(--c-background-100);

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      opacity: 0;
      z-index: -1;

      transition: opacity 200ms ease-in-out;

      svg {
        width: 16rem;
        margin-bottom: 4.8rem;

        &.dark {
          fill: var(--c-iridium-white);
        }
      }

      p {
        margin-bottom: 4.8rem;
      }

      a {
        text-decoration: underline;
      }

      &.show {
        opacity: 1;
        z-index: 4;
      }
    }

    .intro,
    .message {
      h2 {
        font-size: 2.4rem;
        line-height: 1;
        text-align: center;
        margin-bottom: 1.6rem;
      }

      p {
        text-align: center;
        line-height: 1.4;
        color: var(--c-element-200);
      }
    }
  }

  .recaptchaContainer {
    display: none;
  }

  &.open {
    right: 0;
    z-index: 4;

    .content {
      opacity: 1;
    }
  }
}

@keyframes rotating {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes slide-in-right {
  0% {
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
